import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NullEmptyChecker } from '@deliverysolutions/utils';
import * as moment from 'moment-timezone';
import { ReturnService } from '@features/returns/services/returns.service';
import { lastValueFrom } from 'rxjs';
import {
  CUSTOM_ERROR_CODES,
  ERROR_MESSAGE_TEMPLATE,
} from '@constants/generic-constants';
import { Store } from '@ngrx/store';
import { getConfig } from '@store/selectors/config.selector';
import { StorageService } from '@services/storage.service';
import { showToastError } from '@store/actions/error.action';

@Component({
  selector: 'app-request-status',
  templateUrl: './return-request-status.component.html',
  styleUrls: ['./return-request-status.component.scss'],
})
export class ReturnRequestStatusComponent implements OnInit, OnDestroy {
  showQrCode = false;
  @Input() status!: string;
  @Input() orderId!: string;
  @Input() showPrintLabelOption = false;
  @Input() showPrintPackingSlipOption = false;
  @Input() returnAnotherItemOption!: boolean;
  @Input() urlParams: any;
  @Input() showMessage = true;
  @Input() returnOrder!: any;
  @Input() returnRequestDetails!: any;
  scanCodeLink!: string;
  returnsUrl = '';
  @Input() pickupWindow!: any;
  showPickupWindow = false;
  timeStr!: string;
  @Input() errorCode!: string;
  isContactLink = false;
  contactUrl = '';
  itemUniqueId!: string | null;

  constructor(
    private returnService: ReturnService,
    private store: Store,
    private storageService: StorageService,
  ) { }

  @Input() set scanCodeLinkData(value: string) {
    if (value) {
      this.showQrCode = true;
      this.scanCodeLink = value;
    }
  }

  urlParamsLink = '';
  errorMsg = 'Your return request could not be processed.';

  ngOnInit(): void {
    this.orderId = this.orderId ?? this.urlParams?.orderExternalId;
    const orderId = encodeURIComponent(
      btoa(`${this.urlParams?.orderExternalId}`)
    );
    this.urlParamsLink = `/${this.urlParams?.tenantId}/${this.urlParams?.brandExternalId}/${orderId}`;

    // setting the returnsUrl in case returnRequestDetails are not present
    this.itemUniqueId = this.storageService.get('itemUniqueId');
    if (this.itemUniqueId) {
      this.returnsUrl = `${this.urlParamsLink}/main/returns/${this.itemUniqueId}/${this.urlParams?.orderExternalId}`;
    } else {
      this.returnsUrl = `${this.urlParamsLink}/main/returns`;
    }

    if (this.returnRequestDetails) {
      const encodedOrderExternalId = encodeURIComponent(
        btoa(this.returnRequestDetails.orderBeingReturned)
      );
      if (
        this.returnRequestDetails.itemList &&
        NullEmptyChecker.isNonEmptyArray(this.returnRequestDetails.itemList)
      ) {
        this.returnsUrl = `${this.urlParamsLink}/main/returns/${this.returnRequestDetails.itemList[0].__refId}/${this.urlParams?.orderExternalId}`;
      } else {
        this.returnsUrl = `${this.urlParamsLink}/main/returns`;
      }
    }
    if (
      CUSTOM_ERROR_CODES.includes(this.errorCode) &&
      ERROR_MESSAGE_TEMPLATE[this.errorCode]
    ) {
      this.errorMsg = ERROR_MESSAGE_TEMPLATE[this.errorCode];
    }

    this.store
      .select(getConfig)
      .pipe()
      .subscribe(config => {
        if (config) {
          this.isContactLink = config.components?.needHelp?.active;
          this.contactUrl = config.components?.needHelp?.helpUrl;
        }
      });
  }

  printLabel() {
    window.open(this.returnOrder?.labelLink, '_blank');
  }

  printPackingSlip() {
    window.open(this.returnRequestDetails?.packingSlipLink, '_blank');
  }

  async downloadScanCodeLink(imageUrl: string) {
    const fileData = await lastValueFrom(
      this.returnService.getReturnImage(this.orderId, imageUrl)
    );

    const dataType = fileData.type;
    const fileBlob = new Blob([fileData], { type: dataType });
    const fileObjectUrl = URL.createObjectURL(fileBlob);

    const fileExtension = dataType.split('/').pop();
    const fileName = `${this.orderId}.${fileExtension}`;

    const downloadLink = document.createElement('a');

    downloadLink.setAttribute('href', fileObjectUrl);
    downloadLink.setAttribute('download', fileName);
    downloadLink.style.display = 'none';

    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(fileObjectUrl);
  }

  renderPickupWindow() {
    const {
      estimatedPickupTimeStarts,
      estimatedPickupTimeEnds,
      requestedTime,
    } = this.pickupWindow;
    const epoch =
      estimatedPickupTimeStarts || estimatedPickupTimeEnds || requestedTime;
    if (epoch) {
      this.showPickupWindow = true;
      const date = this.getDate(epoch);
      if (estimatedPickupTimeStarts && estimatedPickupTimeEnds) {
        const from = this.getTime(estimatedPickupTimeStarts);
        const to = this.getTime(estimatedPickupTimeEnds);
        this.timeStr = `Between ${date} ${from} and ${date} ${to}`;
      } else if (estimatedPickupTimeStarts) {
        const from = this.getTime(estimatedPickupTimeStarts);
        this.timeStr = `From ${date} ${from}`;
      } else if (estimatedPickupTimeEnds) {
        const to = this.getTime(estimatedPickupTimeEnds);
        this.timeStr = `By ${to}`;
      } else if (requestedTime) {
        const from = this.getTime(requestedTime);
        this.timeStr = `From ${date} ${from}`;
      }
    }
  }

  getDate(date: number) {
    const timezone = Intl.DateTimeFormat('en-US').resolvedOptions().timeZone;
    return moment.tz(date, timezone).format('MMM DD, YYYY');
  }

  getTime(time: number) {
    const timezone = Intl.DateTimeFormat('en-US').resolvedOptions().timeZone;
    return moment.tz(time, timezone).format('h:mm A');
  }

  ngOnDestroy(): void {
    if (this.itemUniqueId) {
      this.storageService.clear('itemUniqueId');
    }
  }
}
