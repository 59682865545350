<div id="request-placed-component">

	<div class="main-content-area content-area col-12 col-sm-12 col-md-10 col-lg-9 col-xl-7 mx-auto max-764px">

		<ng-container *ngIf="pageStatus === 'error'">
			<div class="summary-error d-flex align-items-center justify-content-center">
				<app-request-status *ngIf="!placeHolderLoader" [status]="pageStatus" [orderId]="rmaId"
					[urlParams]="urlEntities" [returnOrder]="returnRequestDetails?.returnOrder"
					[returnRequestDetails]="returnRequestDetails" [errorCode]="errorMsgCode">
				</app-request-status>
			</div>
		</ng-container>

		<ng-container *ngIf="pageStatus !== 'error' && placeHolderLoader">
			<div class="p-3 mb-2 mb-md-3 placeholder-loading bg-white" *ngFor="let arr of placeholderLoaderArr">
				<div class="col-8 col-md-4 data-row mb-1 linear-background"></div>
				<div class="col-6 col-md-3 data-row mb-1 linear-background"></div>
				<div class="col-6 col-md-3 data-row linear-background"></div>
			</div>
		</ng-container>

		<ng-container *ngIf="pageStatus !== 'error' && !placeHolderLoader">

			<app-page-header
				[showBackButtonIcon]="(pageParam && pageParam === 'return-order-details' && itemOb) ? true : false"
				[title]="'Return Summary'"
				[alignment]="(pageParam && pageParam === 'return-order-details' && itemOb) ? 'center' : 'left'"
				(backButtonClick)="goToItemlist()"></app-page-header>

			<div class="d-flex flex-md-row flex-column justify-content-between rma-id"
				*ngIf="(!itemOb && rmaId) || itemOb && !itemOb.exchangedItem" [ngClass]="{'pb-20px':!itemOb}">
				<div class="d-flex justify-content-between gap-1">
					<span class="rma-text">RMA ID:</span>
					<span class="rma-val">{{rmaId}}</span>
				</div>
				<div class="d-flex justify-content-between gap-1 estimated-fee">
					<span class="rma-text">{{'RETURN_REQUEST.ESTIMATED_FEE' | translate}}:</span>
					<span class="rma-val">
						{{ ((this.returnRequestDetails?.fee | typeof) === 'number' &&
						this.returnRequestDetails?.fee !==0) ?
						(this.returnRequestDetails!.fee! / 100 | currency: 'USD':'symbol':'1.2-2') : 'GLOBAL.FREE'
						| translate}}
					</span>
				</div>
			</div>
			<app-item *ngIf="itemOb && !itemOb.exchangedItem" [item]="itemOb" [showActionables]="false" [bgWhite]="true"
				[summaryView]="true" [showModal]="false" [itemHiddenFields]="hiddenFields" [returnFee]="isMobileView ? returnRequestDetails.fee : undefined"></app-item>

			<!-- Start Exchanging Item -->
			<div class="mt-3 pb-3 px-4 border-bottom exchanging-item-info" *ngIf="itemOb && itemOb.exchangedItem">
				<div class="d-flex flex-md-row flex-column justify-content-between w-100 exchanging-item-info-top">
					<div class="d-flex justify-content-between rma-id" [ngClass]="{'pb-20px':!itemOb}">
						<span class="rma-text">RMA ID:</span>
						<span class="rma-val">{{rmaId}}</span>
					</div>
					<div class="d-flex justify-content-between rma-id" [ngClass]="{'pb-20px':!itemOb}">
						<span class="rma-text">{{'RETURN_REQUEST.ESTIMATED_FEE' | translate}}:</span>
						<span class="rma-val">
							{{ ((this.returnRequestDetails?.fee | typeof) === 'number' &&
							this.returnRequestDetails?.fee !==0) ?
							(this.returnRequestDetails!.fee! / 100 | currency: 'USD':'symbol':'1.2-2') : 'GLOBAL.FREE'
							| translate}}
						</span>
					</div>
				</div>
				<app-exchanging-item-info [itemList]="[itemOb]" [isExchangeSelected]="true"
					[hiddenFields]="{returnReason: true}" [returnReason]="returnReason"
					[inventoryMetaData]="inventoryMetaData"></app-exchanging-item-info>
			</div>
			<!-- End Exchanging Item -->

			<div class="d-flex flex-column additional-details"
				*ngIf="returnMethod && (returnMethod.type === methodsType.HOME_PICKUP || returnMethod.type === methodsType.HOME_PICKUP_QR_CODE || returnMethod.type === methodsType.HOME_PICKUP_QR_CODE_BOX) && pageStatus && pageStatus !== 'processing'">
					<div class="d-flex">
						<span class="pe-3 pt-1">
							<i-feather class="d-flex justify-content-center align-items-center map-pin-icon"
								name="map-pin"></i-feather>
						</span>
						<app-content-box [heading]="'Pickup Address'" [content]="addressDetails"
							[firstItem]="true"></app-content-box>
					</div>
					<div class="d-flex return-smart-window" *ngIf="selectedReturnSmartWindow">
						<app-return-smart-window-details [smartWindow]="selectedReturnSmartWindow"
							[isSummaryPage]="true" [hideElement]="{'changePickupWindowBtn':true}"
							[pickupInstruction]="selectedReturnSmartWindow.pickupInstructions"></app-return-smart-window-details>
					</div>
					<div class="d-flex flex-column gap-1 return-smart-window-dsp-group"
						*ngIf="(providerInfo && dspService.getDSPName(providerInfo)) || selectedReturnSmartWindow?.pickupInstructions">
						<div class="d-flex w-100" *ngIf="providerInfo && dspService.getDSPName(providerInfo)">
							<span class="pe-2 return-smart-window-dsp-logo"><img alt="dsp-logo"
									[src]="dspService.getDSPLogo(providerInfo)" width="24" height="24" /></span>
							<span class="return-smart-window-dsp-text">Provided by
								{{dspService.getDSPName(providerInfo)}}</span>
						</div>
						<span></span>
						<div *ngIf="selectedReturnSmartWindow?.pickupInstructions"
							class="return-smart-window-pickup-instruction-group">
							<span class="font-weight-500 text-black return-smart-window-pickup-instruction-header">
								{{"SMART_WINDOW.YOUR_PICKUP_INSTRUCTIONS" | translate}}:
							</span>
							<span class="return-smart-window-pickup-instruction-body">
								{{selectedReturnSmartWindow?.pickupInstructions}}
							</span>
						</div>
					</div>
				<!-- <div class="d-md-flex d-none flex-column gap-1 w-100 return-smart-window-dsp-group"
					*ngIf="(providerInfo && dspService.getDSPName(providerInfo)) || selectedReturnSmartWindow?.pickupInstructions">
					<div class="d-flex w-100" *ngIf="providerInfo && dspService.getDSPName(providerInfo)">
						<span class="pe-2 return-smart-window-dsp-logo"><img alt="dsp-logo"
								[src]="dspService.getDSPLogo(providerInfo)" width="24" height="24" /></span>
						<span class="return-smart-window-dsp-text">Provided by
							{{dspService.getDSPName(providerInfo)}}</span>
					</div>
					<span></span>
					<div *ngIf="selectedReturnSmartWindow?.pickupInstructions"
						class="return-smart-window-pickup-instruction-group">
						<span class="font-weight-500 text-black return-smart-window-pickup-instruction-header">
							{{"SMART_WINDOW.YOUR_PICKUP_INSTRUCTIONS" | translate}}:
						</span>
						<span class="return-smart-window-pickup-instruction-body">
							{{selectedReturnSmartWindow?.pickupInstructions}}
						</span>
					</div>
				</div> -->
			</div>

			<div class="instruction-box" *ngIf="returnInstructions || showPrintLabelButton || scanCodeLinks.length">
				<div class="instruction-title" *ngIf="returnInstructions">Return Instructions</div>
				<div class="white-box bg-white">
					<div class="align-items-center d-flex justify-content-center swiper-pagination-container"
						[class.d-none]="scanCodeLinks.length<2">
						<i-feather class="d-flex cursor-pointer swiper-pagination-btn" name="chevron-left"
							(click)="paginateScanCodeLink('PREV')"></i-feather>
						<div class="swiper-pagination"></div>
						<i-feather class="d-flex cursor-pointer swiper-pagination-btn" name="chevron-right"
							(click)="paginateScanCodeLink('NEXT')"></i-feather>
					</div>
					<div *ngIf="returnInstructions" class="instructions" [innerHTML]="returnInstructions"></div>
					<swiper-container *ngIf="scanCodeLinks.length" appSwiper #swiper [pagination]="true"
						[config]="scanCodeSwiperConfig" init="false">
						<swiper-slide *ngFor="let scanCodeLink of scanCodeLinks">
							<div class="swiper-wrapper">
								<!-- Start Request Status -->
								<app-request-status class="d-flex justify-content-center align-items-center"
									*ngIf="!placeHolderLoader" [orderId]="rmaId"
									[showPrintLabelOption]="showPrintLabelButton" [urlParams]="urlEntities"
									[returnOrder]="returnRequestDetails?.returnOrder" [scanCodeLinkData]="scanCodeLink"
									[returnRequestDetails]="returnRequestDetails" [status]="pageStatus"
									[showMessage]="false" [showPrintPackingSlipOption]="showPrintPackingSlipButton"></app-request-status>
								<!-- End -->
							</div>
						</swiper-slide>
					</swiper-container>
					<!-- Start Request Status -->
					<app-request-status *ngIf="!placeHolderLoader && !scanCodeLinks?.length" [orderId]="rmaId"
						[showPrintLabelOption]="showPrintLabelButton" [urlParams]="urlEntities"
						[returnOrder]="returnRequestDetails?.returnOrder" [returnRequestDetails]="returnRequestDetails"
						[status]="pageStatus" [showMessage]="false" [showPrintPackingSlipOption]="showPrintPackingSlipButton"></app-request-status>
					<!-- End -->
					<div *ngIf="pageStatus !== 'error' && placeHolderLoader"
						class="bg-white mb-2 placeholder-loading status-placeholder d-flex flex-column justify-content-center">
						<div class="status-round mb-2 linear-background data-row mx-auto"></div>
						<div class="col-6 col-md-4 linear-background mb-2 data-row mx-auto max-203px"></div>
						<div class="col-5 col-md-3 linear-background mb-2 data-row mx-auto"></div>
					</div>

				</div>
			</div>

			<div class="additional-details">
				<div class="additional-details-title">Additional Details</div>
				<ng-container *ngIf="itemOb">
					<app-content-box [heading]="'Return Quantity'" [subHeading]="itemOb.quantity+''"
						[firstItem]="true"></app-content-box>
				</ng-container>
				<ng-container *ngIf="returnReason && pageStatus && pageStatus !== 'processing'">
					<app-content-box [heading]="'Return Reason'" [subHeading]="returnReason.name">
						<div class="pt-3 extra-content">
							<ng-container *ngIf="returnReason?.proofOfReturnReason">
								<span class="extra-content-title">
									{{'RETURN_REQUEST.PROOF_FOR_RETURN_REASON' | translate}}:
								</span>
								<div class="d-flex py-2 stack-images">
									<ng-container *ngFor="let proof of returnReason?.proofOfReturnReason; index as i">
										<img tabindex="0" class="rounded border border-2 cursor-pointer border-white"
											[id]="proof.fileId" (keydown)="openModalComponent(previewModal, i)"
											(click)="openModalComponent(previewModal, i)" [src]="proof.fileUrl || proof"
											alt="proof-of-return-reason" width="40px" height="40px" />
									</ng-container>
								</div>
							</ng-container>
							<div *ngIf="returnReason.additionalComments">
								<span class="extra-content-title">
									{{'RETURN_REQUEST.ADDITIONAL_COMMENT' | translate}}:
								</span>
								{{returnReason.additionalComments}}
							</div>
						</div>
					</app-content-box>
				</ng-container>

				<app-content-box *ngIf="returnMethod && pageStatus && pageStatus !== 'processing'"
					[heading]="'Return Method'" [subHeading]="returnMethod.name"></app-content-box>

				<ng-container
					*ngIf="eligibleMethod && eligibleMethod.customerAddressRequired && (returnMethod.type === methodsType.SHIP_IT_BACK || returnMethod.type === methodsType.SHIP_IT_BACK_QR_CODE || returnMethod.type === methodsType.SHIP_IT_BACK_QR_CODE_BOX) && pageStatus && pageStatus !== 'processing'">
					<app-content-box [heading]="'Your Address'" [content]="addressDetails"></app-content-box>
				</ng-container>

				<ng-container *ngIf="pageStatus !== 'error' && !placeHolderLoader">

					<div *ngIf="nearestStorePlaceHolderLoader"
						class="p-3 mb-2 mb-md-3 placeholder-loading bg-white mt-2">
						<div class="col-8 data-row mb-1 linear-background"></div>
						<div class="col-6 data-row mb-1 linear-background"></div>
						<div class="col-4 data-row mb-1 linear-background"></div>
						<div class="col-3 data-row mb-1 linear-background"></div>
						<div class="col-2 data-row linear-background"></div>
					</div>
					<app-dropoff-location
						*ngIf="returnMethod?.type === methodsType.RETURN_TO_STORE && pageStatus && pageStatus !== 'processing' && deliveryAddress"
						[title]="'Nearest Store'" [linkName]="'View Other Nearby Store'"
						(byLinkClicked)="openDropOffLocations()" [location]="dropOffLocation"
						[customCss]="'nearest-store-summary'"></app-dropoff-location>

				</ng-container>

				<app-content-box *ngIf="modeOfRefund && pageStatus && pageStatus !== 'processing'"
					[heading]="'Refund Method'" [subHeading]="modeOfRefund.name" [lastItem]="true"></app-content-box>

			</div>


			<div class="return-another-item-wrapper d-flex justify-content-center">
				<app-return-cancel-button [hostedReturnConfig]="hostedReturnsConfig" [rmaId]="rmaId"
					[status]="returnRequestDetails.status" [returnOrder]="returnRequestDetails.returnOrder ?? null"
					[urlEntities]="urlEntities" [summaryView]="true"></app-return-cancel-button>
				<app-request-status *ngIf="showReturnAnotherItemLink" [status]="pageStatus" [orderId]="rmaId"
					[urlParams]="urlEntities" [returnOrder]="returnRequestDetails?.returnOrder"
					[returnRequestDetails]="returnRequestDetails" [returnAnotherItemOption]="showReturnAnotherItemLink"
					[showMessage]="false">
				</app-request-status>
			</div>

		</ng-container>

		<div *ngIf="pageStatus !== 'error'">
			<div class="mb-2 mb-md-3" *ngIf="primaryAds && primaryAds.active && primaryAds.url">
				<div class="col p-0">
					<app-single-ad [adConfig]="primaryAds" [eventName]="'primary-ads'"></app-single-ad>
				</div>
			</div>

			<ds-recommendations [recommendations]="recommendations" *ngIf="recommendations"></ds-recommendations>

			<div *ngIf="secondaryAdsOne && secondaryAdsOne.active && secondaryAdsOne.url">
				<div class="col p-0">
					<app-single-ad [adConfig]="secondaryAdsOne" [eventName]="'secondary-ads-one'"></app-single-ad>
				</div>
			</div>
			<div class="mb-3" *ngIf="secondaryAdsOne && secondaryAdsTwo"></div>
			<div *ngIf="secondaryAdsTwo && secondaryAdsTwo.active && secondaryAdsTwo.url">
				<div class="col p-0">
					<app-single-ad [adConfig]="secondaryAdsTwo" [eventName]="'secondary-ads-two'"></app-single-ad>
				</div>
			</div>
		</div>

	</div>

	<div class=" col-12 col-sm-12 col-md-10 col-lg-9 col-xl-7 mx-auto max-764px">
		<app-policy-footer></app-policy-footer>
	</div>
	<app-sidenav *ngIf="showSidenav" (bySidenavClosed)="closeDropOffLocations()" [headerTitle]="'Nearby Stores'">
		<app-nearby-dropoff-location (byClose)="sidenavInstance.close()"></app-nearby-dropoff-location>
	</app-sidenav>
</div>

<ng-template #previewModal let-modal>
	<app-ds-modal (close)="closeModalComponent()">
		<!-- Body content projection -->
		<div class="d-flex justify-content-end align-items-end p-3 w-100 h-100" modal-header-content>
			<i-feather class="cursor-pointer" name="x" (click)="modal.dismiss('Cross click')"></i-feather>
		</div>

		<!-- Body content projection -->
		<div class="user-select-none p-2 w-100 h-100" modal-body-content>
			<swiper-container id="previewModalSwiper" *ngIf="returnReason?.proofOfReturnReason?.length" appSwiper
				#swiper [pagination]="true" [config]="swiperConfig" init="false">
				<swiper-slide *ngFor="let proof of returnReason?.proofOfReturnReason">
					<div class="d-flex justify-content-center align-items-center swiper-wrapper">
						<!-- Start Request Status -->
						<img class="rounded border border-2 cursor-pointer border-white preview-image"
							[src]="proof.fileUrl || proof" alt="proof-of-return-reason" />
						<!-- End -->
					</div>
				</swiper-slide>
			</swiper-container>
		</div>

		<!-- Footer content projection -->
		<div class="d-flex justify-content-start align-items-start p-3 w-100 h-100" modal-footer-content>
			<div class="d-flex justify-content-between align-content-between w-100 swiper swiper-pagination-container">
				<i-feather [class.swiper-pagination-disabled]="this.swiperPageIndex-1 === 0"
					[class.cursor-pointer]="this.swiperPageIndex-1 !== 0" class="d-flex swiper-pagination-btn"
					name="arrow-left" (click)="paginatePreviewModal('PREV')"></i-feather>
				<div class="user-select-none swiper-pagination">
					{{this.swiperPageIndex}} of {{returnReason!.proofOfReturnReason!.length}}
				</div>
				<i-feather
					[class.swiper-pagination-disabled]="returnReason!.proofOfReturnReason!.length === this.swiperPageIndex"
					[class.cursor-pointer]="returnReason!.proofOfReturnReason!.length !== this.swiperPageIndex"
					class="d-flex swiper-pagination-btn" name="arrow-right"
					(click)="paginatePreviewModal('NEXT')"></i-feather>
			</div>
		</div>
	</app-ds-modal>
</ng-template>