import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NullEmptyChecker } from '@deliverysolutions/utils';
import { MethodsCategory } from '@core/constants/method-category';
import { SidenavComponent } from '@core/components/sidenav/sidenav.component';
import { AddressTypes } from '@core/constants/addressTypes-constants';
import { ReturnMethodGroup } from '@core/models/return-method-group';
import { HostedReturnsConfig, ReturnLabelBox } from '@core/models/returns-config.interface';
import { Address } from '@core/models/address';
import { ReturnWindow } from '@core/models/return-smart-windows';
import { DateFragment, getDateFragment } from '@core/utils/date-utils';
import { DSPService, DspType } from '@services/dsp.service';
import { ReturnOrder } from '@core/models/return-request';
import { Store } from '@ngrx/store';
import { getConfig } from '@store/selectors/config.selector';

type ReturnMethodCardHideElement = {
  viewDetailsBtn?: boolean;
  printLabelsBtn?: boolean;
  printPackingSlipBtn?: boolean;
  instructionsBtn?: boolean;
  changeAddressBtn?: boolean;
  changePickupWindowBtn?: boolean;
  titleReturnFee?: boolean;
  keepTheItem?: boolean;
  returnLabelBox?: boolean;
  itemList?: boolean;
};

@Component({
  selector: 'app-return-method-card',
  templateUrl: './return-method-card.component.html',
  styleUrls: ['./return-method-card.component.scss'],
})
export class ReturnMethodCardComponent implements OnInit, OnDestroy {
  @Output() byNavToInstructions = new EventEmitter();
  @Output() byNavToItemList = new EventEmitter();
  @Output() byPrintLabels = new EventEmitter();
  @Output() byPrintPackingSlip = new EventEmitter();
  @Output() byChangeAddress = new EventEmitter();
  @Output() byViewNearByStore = new EventEmitter();
  @Output() bySelectReturnLabelBox = new EventEmitter();
  @Output() bySetReturnSmartWindow = new EventEmitter();
  @Input() hideElement: ReturnMethodCardHideElement = {
    viewDetailsBtn: false,
    printLabelsBtn: true,
    printPackingSlipBtn: true,
    instructionsBtn: true,
    changeAddressBtn: false,
    changePickupWindowBtn: false,
    titleReturnFee: true,
    keepTheItem: false,
    returnLabelBox: true,
    itemList: false,
  };
  @Input() useSideDrawerForAction = true;
  @Input() lastItem!: boolean;
  @Input() returnSmartWindowSummaryView = false;
  @Input() returnMethod!: ReturnMethodGroup;
  @Input() providerInfo?: DspType;
  @Input() index!: number;
  @Input() returnStatus!: string;
  @Input() returnFeeLoader = false;
  @Input() returnOrder!: ReturnOrder | null;
  @Input() urlEntities!: any;
  @ViewChild(SidenavComponent) sideNavInstance!: SidenavComponent;
  addressTypes = AddressTypes;
  showNearByStoreSideDrawer = false;
  showChangeAddressSideDrawer = false;
  showReturnSmartWindowNav = false;
  showReturnMethodViewDetails = false;
  sideNavHeaderTitle = 'View Details';
  selectedReturnSmartWindow?: ReturnWindow;
  selectedReturnSmartWindowDate?: DateFragment;
  routeDataSubscription!: Subscription;
  returnMethodsCategory = MethodsCategory;
  returnLabelBoxes: ReturnLabelBox[] = [];
  returnLabelBoxesOptions: { data: ReturnLabelBox, title: string }[] = [];
  hostedReturnsConfig!: HostedReturnsConfig;
  isContactLink = false;
  contactUrl = '';
  returnLabelBoxConfig: { prefix: 'currency' | 'string', prefixDefault: string } = { prefix: 'string', prefixDefault: 'I need labels for' };

  constructor(
    private activatedRoute: ActivatedRoute,
    public dspService: DSPService,
    private store: Store,
  ) {
    this.routeDataSubscription = this.activatedRoute.data.subscribe(data => {
      if (!NullEmptyChecker.isNull(data['configResp'])) {
        this.hostedReturnsConfig = data['configResp'];
        this.prepareReturnLabelBoxes(data['configResp']);
      }
    });
  }

  ngOnInit(): void {
    if (this.returnMethod.address)
      this.formatAddress(this.returnMethod.address);
    if (this.returnMethod.pickupTime) {
      const window: ReturnWindow = {
        pickupTime: this.returnMethod.pickupTime!,
        dropoffTime: this.returnMethod.dropoffTime!,
        tz: this.returnMethod.timeZone!,
        windowId: undefined,
        provider: undefined,
      };
      const pickupDate = new Date(this.returnMethod.pickupTime.startsAt);
      const date: DateFragment = getDateFragment(pickupDate, 0);
      const timezone = this.returnMethod.timeZone ?? "";
      this.setReturnSmartWindow({ window, date, timezone });
    }

    this.store
    .select(getConfig)
    .pipe()
    .subscribe(config => {
      if (config) {
        this.isContactLink = config.components?.needHelp?.active;
        this.contactUrl = config.components?.needHelp?.helpUrl;
      }
    });
    this.prepareReturnLabelBoxConfig();
  }

  ngOnDestroy(): void {
    if (this.routeDataSubscription) this.routeDataSubscription.unsubscribe();
  }

  prepareReturnLabelBoxes(hostedReturnsConfig: HostedReturnsConfig) {
    const boxSelection = hostedReturnsConfig.componentVisibility.boxSelection;
    if (boxSelection) {
      const boxLimitArr: number[] = Array.from(
        { length: boxSelection.boxLimit },
        (_, i) => i + 1
      );

      const returnLabelBoxes: ReturnLabelBox[] = [
        ...boxLimitArr.map((value: number) => ({
          name: value + ' Box',
          value,
        })),
      ];

      this.returnLabelBoxes = returnLabelBoxes;
      this.returnLabelBoxesOptions = this.getReturnLabelBoxOptions();
    }
  }

  getReturnLabelBoxOptions() {
    const returnLabelBoxes = this.returnLabelBoxes.map(
      (returnLabelBox: ReturnLabelBox) => ({
        data: returnLabelBox,
        title: returnLabelBox.name,
      })
    );
    return returnLabelBoxes;
  }

  selectReturnLabelBoxOption(selectedReturnLabelBox: ReturnLabelBox) {
    this.returnMethod.returnLabelBox = selectedReturnLabelBox.value;
    this.bySelectReturnLabelBox.emit(selectedReturnLabelBox);
    this.calculateReturnFee();
  }

  navToInstructions() {
    this.byNavToInstructions.emit(this.returnMethod.rmaId);
  }

  navToItemList() {
    this.byNavToItemList.emit();
  }

  printLabels() {
    this.byPrintLabels.emit(this.returnMethod.code);
  }

  printPackingSlip() {
    this.byPrintPackingSlip.emit(this.returnMethod.rmaId);
  }

  setReturnSmartWindow({
    window,
    date,
    timezone,
  }: {
    window: ReturnWindow;
    date: DateFragment;
    timezone: string;
  }) {
    this.selectedReturnSmartWindow = window;
    this.selectedReturnSmartWindowDate = date;
    this.returnMethod.pickupTime = window.pickupTime;
    this.returnMethod.dropoffTime = window.dropoffTime;
    this.returnMethod.timeZone = timezone;
    if (!this.useSideDrawerForAction) {
      this.bySetReturnSmartWindow.emit({
        window,
        date,
        timezone,
      });
    }
  }

  setReturnSmartWindowPickupInstruction(pickupInstructions?: string) {
    this.returnMethod.pickupInstructions = pickupInstructions;
  }

  toggleReturnSmartWindowNav(showReturnSmartWindowNav = true) {
    this.showReturnSmartWindowNav = showReturnSmartWindowNav;
  }

  toggleNearByStoreSideDrawer() {
    this.showNearByStoreSideDrawer = !this.showNearByStoreSideDrawer;
    this.changeSideNavHeaderTitle('Nearby Stores');
  }

  changeSideNavHeaderTitle(changeAddress: string) {
    this.sideNavHeaderTitle = changeAddress;
  }

  viewNearByStore() {
    if (this.useSideDrawerForAction) {
      this.toggleNearByStoreSideDrawer();
    } else {
      this.byViewNearByStore.emit();
    }
  }

  changeAddress() {
    if (this.useSideDrawerForAction) {
      this.toggleAddressSideDrawer();
    } else {
      this.byChangeAddress.emit();
    }
  }

  toggleAddressSideDrawer() {
    this.showChangeAddressSideDrawer = !this.showChangeAddressSideDrawer;
  }

  toggleViewDetailsSideDrawer() {
    this.showReturnMethodViewDetails = !this.showReturnMethodViewDetails;
    this.changeSideNavHeaderTitle('View Details');
  }

  setAddress(e: { address: Address }, clsoeSideNav: boolean) {
    this.returnMethod.address = e.address;
    if (NullEmptyChecker.isNonEmptyArray(this.returnMethod.itemList)) {
      this.returnMethod.itemList![0].address = e.address;
    }
    this.formatAddress(e.address);
    if (clsoeSideNav) this.sideNavInstance.close();
  }

  calculateReturnFee() {
    const returnFee = this.returnMethod.returnFee
      ? this.returnMethod.returnFee * (this.returnMethod.returnLabelBox || 1)
      : 0;

    this.returnMethod.labelBoxReturnFee = returnFee;
  }

  formatAddress(address: Address) {
    this.returnMethod.addressStr = `
			${address?.street},
			${address?.street2 ? address.street2 + ',' : ''}
			${address?.city},
			${address?.state},
			${address?.zipcode},
			${address?.country}
		`;
  }

  prepareReturnLabelBoxConfig() {
    if (!this.returnMethod?.type) return;
    if (
      this.returnMethod.type === this.returnMethodsCategory.SHIP_IT_BACK_QR_CODE || 
      this.returnMethod.type === this.returnMethodsCategory.HOME_PICKUP_QR_CODE
    ) {
      this.returnLabelBoxConfig.prefixDefault = 'I need scan codes for';
    } else {
      this.returnLabelBoxConfig.prefixDefault = 'I need labels for';
    }
  }
}
