import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MethodsCategory } from '@constants/method-category';
import { Item } from '@core/models/item';
import { ReturnMethod } from '@core/models/return-method';
import { ReturnMethodGroup } from '@core/models/return-method-group';
import {
  ExchangeMetadataAttribute,
  ReturnRequest,
} from '@core/models/return-request';
import { HostedReturnsConfig } from '@core/models/returns-config.interface';
import { NullEmptyChecker } from '@deliverysolutions/utils';
import { ReturnService } from '@features/returns/services/returns.service';
import { Store } from '@ngrx/store';
import { ReturnsConfigService } from '@services/returns-config.service';
import { BaseComponent } from '@shared/components/base.component';
import { SwiperDirective } from '@shared/directive';
import { getItemList } from '@store/selectors/auth.selector';
import { getConfig } from '@store/selectors/config.selector';
import { Subscription, combineLatest } from 'rxjs';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'app-return-summary-details',
  templateUrl: './return-summary-details.component.html',
  styleUrls: ['./return-summary-details.component.scss'],
})
export class ReturnSummaryDetailsComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  rmaId!: string;
  rmaIds!: string[];
  hostedReturnConfig!: HostedReturnsConfig;
  routeDataSubscription!: Subscription;
  requestDetailsSubscription!: Subscription;
  itemListSubscription!: Subscription;
  configSubscription!: Subscription;
  public hiddenFields: { [key: string]: boolean } = {};
  urlEntities!: {
    orderExternalId?: string;
    tenantId?: string;
    brandExternalId?: string;
  } | null;
  returnRequestDetails!: ReturnRequest;
  placeHolderLoader = true;
  showPrintLabelButton = false;
  showPrintPackingSlipButton= false;
  isReturningItem = false;
  returnMethodGroup!: ReturnMethodGroup;
  masterReturnMethods: ReturnMethodGroup[] = [];
  scanCodeLinks: string[] = [];
  itemList!: Item[];
  inventoryMetaData: ExchangeMetadataAttribute[] = [];
  @ViewChild(SwiperDirective)
  swiperDirective!: SwiperDirective;
  swiperConfig: SwiperOptions = {
    enabled: true,
    pagination: {
      el: '.swiper-pagination',
      enabled: true,
      type: 'custom',
      renderCustom(swiper, current, total) {
        return current + ' of ' + total;
      },
    },
    autoHeight: true,
    allowTouchMove: true,
    slidesPerGroup: 1,
    slidesPerView: 1,
    loop: true,
    rewind: true,
    spaceBetween: 30,
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private returnService: ReturnService,
    private store: Store,
    public configService: ReturnsConfigService
  ) {
    super({ hostedReturnService: configService });
  }
  ngOnInit(): void {
    this.routeDataSubscription = combineLatest([
      this.activatedRoute.data,
      this.activatedRoute.paramMap,
      this.activatedRoute.queryParamMap,
    ]).subscribe(([data, params, queryParam]) => {
      this.hostedReturnConfig = data['configResp'];
      this.rmaId = params.get('rmaId') ?? '';
      const rmaIds = queryParam.get('rId') || '';
      this.rmaIds = rmaIds.split(',');
    });
    this.init();
  }

  ngOnDestroy(): void {
    if (this.routeDataSubscription) this.routeDataSubscription.unsubscribe();
    if (this.requestDetailsSubscription)
      this.requestDetailsSubscription.unsubscribe();
    if (this.itemListSubscription) this.itemListSubscription.unsubscribe();
    if (this.configSubscription) this.configSubscription.unsubscribe();
  }

  paginateScanCodeLink(direction: 'NEXT' | 'PREV') {
    switch (direction) {
      case 'NEXT':
        this.swiperDirective.next();
        break;
      case 'PREV':
        this.swiperDirective.prev();
        break;
    }
  }

  init() {
    this.urlEntities = this.getTenantAndBrandExternalId();
    this.configSubscription = this.store
      .select(getConfig)
      .pipe()
      .subscribe(config => {
        if (config) {
          this.hiddenFields = this.returnsConfigService.setHiddenFields(config);
        }
      });
    this.fetchBulkRequestDetailsByRmaId([this.rmaId]);
  }

  fetchBulkRequestDetailsByRmaId(rmaIds: string[]) {
    this.requestDetailsSubscription = this.returnService
      .getBulkRequestDetailsById(rmaIds)
      .subscribe({
        next: respData => {
          const respDatas = respData as ReturnRequest[];
          const [returnRequestDetail] = respDatas;
          this.returnRequestDetails = returnRequestDetail;

          const labelLink = returnRequestDetail?.returnOrder?.labelLink;
          const scanCodeLink = returnRequestDetail?.returnOrder?.scanCodeLink;
          const returnMethod = returnRequestDetail?.returnMethod;
          if (
            NullEmptyChecker.isNonEmptyArray(
              returnRequestDetail?.exchangeMetadata
            )
          ) {
            this.inventoryMetaData =
              returnRequestDetail.exchangeMetadata!.flatMap(
                (metaData: { attributes: any }) => metaData.attributes
              );
          }

          this.isReturningItem = !!this.returnRequestDetails.itemList?.some(
            item => !item.exchangedItem
          );

          if (
            (returnMethod.type === MethodsCategory.SHIP_IT_BACK ||
              returnMethod.type === MethodsCategory.HOME_PICKUP) &&
            labelLink
          ) {
            this.showPrintLabelButton = true;
          } else if (this.isQRCodeMethodType(returnMethod.type)) {
            const shipments = returnRequestDetail?.returnOrder?.shipments;

            if (NullEmptyChecker.isNonEmptyArray(shipments)) {
              this.scanCodeLinks = shipments!
                .filter(shipment => shipment.scanCodeLink)
                .map(shipment => shipment.scanCodeLink);
            }

            if (
              !NullEmptyChecker.isNonEmptyArray(this.scanCodeLinks) &&
              scanCodeLink
            ) {
              this.scanCodeLinks = [scanCodeLink];
            }

            if (NullEmptyChecker.isNonEmptyArray(this.scanCodeLinks)) {
              this.showPrintLabelButton = false;
            }
          }

          if (
            this.hostedReturnConfig.componentVisibility?.packingSlip?.active 
          ) {
            this.showPrintPackingSlipButton = true;
          }

          this.getMasterReturnMethods();
        },
        error: err => {
          console.error('error while fetching request=>', err);
        },
      });
  }

  isQRCodeMethodType(returnMethod?: string): boolean {
    if (
      returnMethod &&
      [
        MethodsCategory.HOME_PICKUP_QR_CODE.toString(),
        MethodsCategory.HOME_PICKUP_QR_CODE_BOX.toString(),
        MethodsCategory.SHIP_IT_BACK_QR_CODE.toString(),
        MethodsCategory.SHIP_IT_BACK_QR_CODE_BOX.toString(),
      ].includes(returnMethod)
    ) {
      return true;
    }

    return false;
  }

  getMasterReturnMethods() {
    if (NullEmptyChecker.isNull(this.returnRequestDetails)) {
      return;
    }
    const codes: string[] = <string[]>[
      this.returnRequestDetails.returnMethod.code,
    ];

    this.placeHolderLoader = true;
    this.returnService.getMasterReturnMethods(codes).subscribe({
      next: methods => {
        this.placeHolderLoader = false;
        methods = methods.filter((method: ReturnMethod) => method.active);
        if (NullEmptyChecker.isNonEmptyArray(methods)) {
          this.masterReturnMethods = methods;
          this.getItemList();
        }
      },
      error: error => {
        this.placeHolderLoader = false;
        console.error('Error while fetching master return methods=>', error);
      },
    });
  }

  getItemList() {
    this.itemListSubscription = this.store
      .select(getItemList)
      .pipe()
      .subscribe((resp: Item[]) => {
        this.itemList = resp;
        this.prepareReturnMethod();
      });
  }

  prepareReturnMethod() {
    let returnMethodGroup: Partial<ReturnMethodGroup> = {};
    const address = this.returnRequestDetails.customerAddress;
    const returnFee = this.returnRequestDetails.fee;
    const returnMethod = this.returnRequestDetails.returnMethod;
    const exchangeMetadata = this.returnRequestDetails.exchangeMetadata;
    const pickupTime = this.returnRequestDetails.pickupTime;
    const dropoffTime = this.returnRequestDetails.dropoffTime;
    const pickupInstructions = this.returnRequestDetails.pickupInstructions;
    const providerInfo = this.returnRequestDetails?.returnOrder?.providerInfo;
    const timeZone = this.returnRequestDetails.timeZone;
    if (NullEmptyChecker.isNonEmptyArray(this.returnRequestDetails?.itemList)) {
      const itemList: Item[] = this.returnRequestDetails.itemList!.map(
        (selectedItem: Item) => {
          const returnMethodItem = { ...selectedItem };

          const item = this.itemList.find(
            item => item.sku === selectedItem.sku
          );

          returnMethodItem.returnFee = returnFee;
          returnMethodItem.address = address;
          returnMethodItem.itemQuantity = returnMethodItem.quantity;
          returnMethodItem.image = item?.image;

          return returnMethodItem;
        }
      );

      if (NullEmptyChecker.isNonEmptyArray(itemList)) {
        if (returnMethodGroup.itemList) {
          returnMethodGroup.itemList = [
            ...returnMethodGroup.itemList,
            ...itemList,
          ];
        } else {
          const item = itemList[0];
          const masterReturnMethod = this.masterReturnMethods.find(
            masterReturnMethod => masterReturnMethod.code === returnMethod.code
          );

          returnMethodGroup = {
            rmaId: Number(this.rmaId),
            returnFee: item?.returnFee,
            labelBoxReturnFee: item?.returnFee,
            error: false,
            address: item?.address,
            itemList,
            code: masterReturnMethod?.code,
            displayName: masterReturnMethod?.displayName,
            type: <MethodsCategory>masterReturnMethod?.type,
            instructions: masterReturnMethod?.instructions,
            ...(exchangeMetadata && { exchangeMetadata }),
            ...(pickupTime && { pickupTime }),
            ...(dropoffTime && { dropoffTime }),
            ...(pickupInstructions && { pickupInstructions }),
            ...(timeZone && { timeZone }),
            ...(providerInfo && { providerInfo }),
          };
        }
      }
    }

    this.returnMethodGroup = <ReturnMethodGroup>returnMethodGroup;
  }

  navBack() {
    history.back();
  }
}
